import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 5,
  class: "table-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserInfo = _resolveComponent("UserInfo")!
  const _component_DeviceInfo = _resolveComponent("DeviceInfo")!
  const _component_Status = _resolveComponent("Status")!
  const _component_Country = _resolveComponent("Country")!
  const _component_Actions = _resolveComponent("Actions")!
  const _component_AppBadge = _resolveComponent("AppBadge")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pseudo-table--cell", {
      [`pseudo-table--cell-size--${_ctx.size}`]: true,
      ['d-flex']: _ctx.rightAlign,
      ['justify-content-end']: _ctx.rightAlign
    }])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.componentInfo)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.componentInfo.name === _ctx.componentNames.cellInfoWithImage)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.componentInfo.content.image)
                  ? (_openBlock(), _createBlock(_component_UserInfo, {
                      key: 0,
                      name: _ctx.componentInfo.content.title,
                      description: _ctx.componentInfo.content.description,
                      avatar: _ctx.componentInfo.content.image,
                      class: "w-100"
                    }, null, 8, ["name", "description", "avatar"]))
                  : (_openBlock(), _createBlock(_component_DeviceInfo, {
                      key: 1,
                      name: _ctx.componentInfo.content.title || '',
                      os: _ctx.componentInfo.content.description
                    }, null, 8, ["name", "os"]))
              ], 64))
            : (_ctx.componentInfo.name === _ctx.componentNames.cellCheckmark)
              ? (_openBlock(), _createBlock(_component_Status, {
                  key: 1,
                  status: _ctx.componentInfo.content
                }, null, 8, ["status"]))
              : (_ctx.componentInfo.name === _ctx.componentNames.cellCountry)
                ? (_openBlock(), _createBlock(_component_Country, {
                    key: 2,
                    "image-path": _ctx.componentInfo.content.icon,
                    name: _ctx.componentInfo.content.name
                  }, null, 8, ["image-path", "name"]))
                : (_ctx.componentInfo.name === _ctx.componentNames.userActions)
                  ? (_openBlock(), _createBlock(_component_Actions, {
                      key: 3,
                      id: _ctx.id,
                      "hide-button-remove": _ctx.hideButton,
                      onEdit: _ctx.editItem,
                      onRemove: _ctx.deleteItem
                    }, null, 8, ["id", "hide-button-remove", "onEdit", "onRemove"]))
                  : (_ctx.componentInfo.name === _ctx.componentNames.cellBadge)
                    ? (_openBlock(), _createBlock(_component_AppBadge, {
                        key: 4,
                        type: _ctx.componentInfo.content.type,
                        size: _ctx.componentInfo.content.size,
                        rounded: _ctx.componentInfo.content.rounded || false
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.componentInfo.content.label), 1)
                        ]),
                        _: 1
                      }, 8, ["type", "size", "rounded"]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.componentInfo.content), 1))
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}