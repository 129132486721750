
import { defineComponent, PropType } from "vue";
import {
  cellSize,
  ITableComponentNames,
  tableContentItem
} from "@/components/Table/ts/interfaces/TableStructure";
import { tableComponentNames } from "@/components/Table/ts/interfaces/TableStructure";
import UserInfo from "@/components/UserInfo/UserInfo.vue";
import DeviceInfo from "@/components/ui/DeviceInfo.vue";
import Status from "@/components/ui/Status.vue";
import Country from "@/components/ui/Country/Country.vue";
import Actions from "@/components/ui/TableActions.vue";
import AppBadge from "@/components/ui/AppBadge/AppBadge.vue";

export default defineComponent({
  name: "AppTableBodyCell",
  components: { AppBadge, Country, DeviceInfo, UserInfo, Status, Actions },
  inheritAttrs: false,
  emits: {
    deleteItem: null,
    editItem: null
  },
  props: {
    size: {
      type: String as PropType<cellSize>,
      default: "xs"
    },

    id: { type: Number, default: 0 },
    componentInfo: {
      type: Object as PropType<{
        name: tableContentItem;
        content: tableContentItem | undefined;
      }>,

      required: false
    },
    hideButton: { type: Boolean, default: false },
    rightAlign: { type: Boolean, default: false }
  },

  data() {
    return {
      componentNames: tableComponentNames
    } as {
      componentNames: ITableComponentNames;
    };
  },

  methods: {
    editItem() {
      this.$emit("editItem", this.id);
    },

    deleteItem() {
      this.$emit("deleteItem", this.id);
    }
  }
});
